import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueSession from 'vue-session'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router'
import vuetify from './plugins/vuetify'
import axiosObject from "./plugins/axios";
import './registerServiceWorker'
import link from "./env"
Vue.config.productionTip = false;
axiosObject.defaults.baseURL = (Vue.config.productionTip) ? link.prod : link.dev;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Vue.use(pinia);
Vue.use(VueMeta);
Vue.use(VueSession);

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');